import { useEffect, useRef, useState } from "react";
import { ReadRecord } from "../../Service/API/ApiService";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  Button,
  Table,
  DatePicker,
  Flex,
  Spin,
  Card,
  theme,
  Radio,
} from "antd";
import {
  LoadingOutlined,
  ExportOutlined,
  SearchOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import formatDate from "../../Components/formatDate";
import logo from "../../Assests/harnetlogo.png";
function ProductionReport() {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const [data, setData] = useState([]);
  const [downloadedData, setDownloadedData] = useState([]);
  const csvDownloadRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [radioutton, setRadioutton] = useState("2");
  const [LabelOptions, setLabeldetails] = useState(null);
  const [ribbonOptions, setRibbonOptions] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [OrderStatusQuery, setOrderStatusQuery] = useState("");
  const [jumboDataForList, setJumboDataForList] = useState(null);
  const [MaterialOptions, setMaterialDetails] = useState(null);
  const [labelAndRibbonData, setlabelAndRibbonData] = useState(null);
  const { RangePicker } = DatePicker;
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const exportPdf = useRef();
  const headers = [
    { label: "Sl.No.", key: "sl_no" },
    { label: "Production ID", key: "production_number" },
    ...(radioutton === "1" ? [{ label: "Order ID", key: "order_number" }] : []), // Conditional addition of "Order ID"
    { label: "Production Date", key: "CreatedDate" },
    { label: "Jumbo Type", key: "jumbo_type" },
    { label: "Material", key: "material_name" },
    { label: "Type", key: "type" },
    { label: "Size", key: "label_id" },
    { label: "Production Quantity", key: "production_label_quantity" },
    { label: "Production Status", key: "production_status" },
    { label: "Production Completed Date", key: "ModifiedDate" },
    { label: "Rejected Reason", key: "rejected_reason" },
  ];

  const columns = [
    {
      title: "Sl.No.",
      dataIndex: "serial",
      key: "serial",
      align: "center",
      width: 10,
      render: (text, record, index) =>
        index + (pagination.current - 1) * pagination.pageSize + 1,
    },
    {
      title: "Production ID",
      dataIndex: "production_number",
      key: "order1",
      align: "center",
      width: 20,
    },
    ...(radioutton === "1"
      ? [
          {
            title: "Order ID",
            dataIndex: "order_number",
            key: "order_number",
            // width: 50,
            align: "center",
          },
        ]
      : []),
    {
      title: "Production Date",
      dataIndex: "CreatedDate",
      key: "CreatedDate",
      // width: 90,
      align: "center",
    },
    // {
    //   title: "Production Status",
    //   dataIndex: "production_status",
    //   key: "productionStatus",
    //   align: "center",
    //   width: 50,
    //   // filters: [
    //   //   { text: "New Request", value: "New Request" },
    //   //   { text: "Pending", value: "Pending" },
    //   //   { text: "Completed", value: "Completed" },
    //   // ],
    //   // onFilter: (value, record) => record.order_status === value,
    // },
    {
      title: "Jumbo Type",
      dataIndex: "jumbo_type",
      key: "jumbo_type",
      // width: 50,
      align: "center",
      filters: jumboDataForList?.map((item) => ({
        text: item.jumbo_type,
        value: item.id,
      })),
      filteredValue: filteredInfo.jumbo_type || null,
    },
    {
      title: "Material",
      dataIndex: "material_name",
      key: "material_name",
      // width: 50,
      align: "center",
      filters: MaterialOptions?.map((item) => ({
        text: item.material_name,
        value: item.material_name,
      })),
      filteredValue: filteredInfo.material_name || null,
      onFilter: (value, record) => record.material_name === value,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "typee",
      // width: 50,
      align: "center",
      filters: [
        { text: "Label", value: "1" },
        { text: "Ribbon", value: "2" },
      ],
      filteredValue: filteredInfo.typee || null,
      onFilter: (value, record) => record.type === value,
      render: (index, record) => (record.type == "1" ? "Label" : "Ribbon"),
    },
    {
      title: "Size",
      dataIndex: "Size",
      key: "Size",
      width: 80,
      align: "center",
      filters: labelAndRibbonData?.map((item) => ({
        text: item.Size,
        value: item.Size,
      })),
      filteredValue: filteredInfo.Size || null,
      onFilter: (value, record) => record.Size === value,
    },
    {
      title: "Production Quantity",
      dataIndex: "production_label_quantity",
      key: "production_label_quantity",
      // width: 50,
      align: "center",
    },
    {
      title: "Production Status",
      dataIndex: "production_status",
      key: "production_status",
      align: "center",
      width: 50,
      filters: [
        { text: "Awaiting Approval", value: "Awaiting Approval" },
        { text: "In Process", value: "In Process" },
        { text: "Completed", value: "Completed" },
        { text: "Rejected", value: "Rejected" },
      ],
      filteredValue: filteredInfo.production_status || null,
      onFilter: (value, record) => record.production_status === value,
    },
    {
      title: "Rejected Reason",
      dataIndex: "rejected_reason",
      key: "rejected_reason",
      align: "center",
      width: 50,
      render: (record) => {
        return record ? record : "-";
      },
    },
  ];
  useEffect(() => {
    const nowIST = dayjs().tz("Asia/Kolkata");
    const startOfMonth = nowIST.startOf("month");
    const endOfMonth = nowIST.endOf("month");

    console.log("startOfMonth", startOfMonth);
    setFromDate(startOfMonth);
    setToDate(endOfMonth);
    getJumboDetailsForList();
    getMaterialDetail();
    getlabelAndRibbonComboDetails();
  }, []);
  function getlabelAndRibbonComboDetails() {
    ReadRecord(
      `SELECT 
        label_master.id AS id,
        '1' AS type,
         CONCAT(label_master.label_width, ' x ', label_master.label_length) AS Size
    FROM
label_master
    UNION ALL SELECT 
        ribbon_master.id AS id,
        '2' AS type,
        CONCAT(ribbon_master.ribbon_width, ' x ', ribbon_master.ribbon_length) AS Size
    FROM
        ribbon_master`
    ).then((res) => {
      setlabelAndRibbonData(res.data[0]?.rows);
    });
  }
  function getMaterialDetail() {
    ReadRecord(
      `SELECT id,material_name FROM material_master WHERE status = 1 and ActiveStatus = 1`
    ).then((res) => {
      setMaterialDetails(res.data[0]?.rows);
    });
  }
  function getJumboDetailsForList() {
    ReadRecord(
      `SELECT id,jumbo_type FROM jumbo_master WHERE status = 1 and ActiveStatus = 1 order by id desc;`
    ).then((res) => {
      setJumboDataForList(res.data[0]?.rows || []);
    });
  }

  useEffect(() => {
    getLabelDetails();
    fetchRibbonOptions();
    if (fromDate && toDate) {
      fetchData(fromDate, toDate, radioutton, OrderStatusQuery);
      setPagination((prevPagination) => ({
        ...prevPagination,
        current: 1,
        pageSize: pagination.pageSize,
      }));
      // fetchDataToDownload(fromDate, toDate);
    } else {
      setData([]);
      setIsLoading(false);
    }
  }, [fromDate, toDate]);
  function getLabelDetails() {
    ReadRecord(
      `SELECT id,label_length,label_width,label_measure FROM label_master WHERE status = 1 and ActiveStatus = 1`
    ).then((res) => {
      setLabeldetails(res.data[0]?.rows);
    });
  }
  const fetchRibbonOptions = async () => {
    try {
      const response = await ReadRecord(
        `SELECT id,ribbon_length,ribbon_width,ribbon_measure FROM ribbon_master WHERE status = 1 and ActiveStatus = 1`
      );
      setRibbonOptions(response.data[0]?.rows || []);
    } catch (error) {
      console.error("Error fetching Label options:", error);
    }
  };
  const fetchDataToDownloadPDF = () => {
    console.log("downloading data", data);
    setIsLoading(true);
    if (fromDate && toDate) {
      const doc = new jsPDF({
        orientation: "landscape",
      });
      doc.setFontSize(18); // Set font size for the title
      doc.text("Harnet India Private Ltd.,", 110, 12);
      doc.setFontSize(12);
      doc.setTextColor(0, 0, 139); // Dark blue color
      doc.text("PRODUCTION DETAILS :", 15, 15);
      const logoImg = logo;
      doc.addImage(
        logoImg,
        "PNG",
        doc.internal.pageSize.getWidth() - 35,
        3,
        20,
        15
      );
      const tableStyles = {
        theme: "grid",
        styles: {
          fontSize: 8, // Adjust the font size here
          halign: "center",
          cellWidth: "wrap",
        },
        margin: { top: 20 },
        columnStyles: {
          rejected_reason: { cellWidth: 50 },
          label_id: { cellWidth: 30 },
          default: { cellWidth: "auto" },
        },
      };

      // Adjusting the columns to include dataKey and styling
      const tableColumns = columns.map((col) => ({
        ...col,
        dataKey: col.dataIndex,
      }));
      const dataWithSerialNumbers = filteredData.map((row, index) => ({
        ...row,
        serial: index + 1, // Add the serial number manually
        rejected_reason: row.rejected_reason ? row.rejected_reason : "-",
        label_id:
          row.type == "1"
            ? LabelOptions?.find((option) => option.id === row.label_id)
              ? `${
                  LabelOptions.find((option) => option.id === row.label_id)
                    ?.label_width
                } x ${
                  LabelOptions.find((option) => option.id === row.label_id)
                    ?.label_length
                }`
              : "-" // Default value in case no matching label is found
            : ribbonOptions?.find((option) => option.id === row.label_id)
            ? `${
                ribbonOptions.find((option) => option.id === row.label_id)
                  ?.ribbon_width
              } x ${
                ribbonOptions.find((option) => option.id === row.label_id)
                  ?.ribbon_length
              }`
            : "-",
        type: row.type == "1" ? "Label" : "Ribbon",
      }));

      // Generate the table with the provided styles
      doc.autoTable(tableColumns, dataWithSerialNumbers, tableStyles);
      const filename = `Production_Report_${
        fromDate ? formatDate(fromDate) : "from"
      }_to_${toDate ? formatDate(toDate) : "to"}.pdf`;
      // Save the PDF
      doc.save(filename);
      setIsLoading(false);
    } else {
      setDownloadedData([]);
    }
  };

  const fetchDataToDownload = (
    fromDate = null,
    toDate = null,
    typeFilter1 = radioutton,
    query = ""
  ) => {
    setIsLoading(true);
    let dateFilter = "";
    let joinFilter = "";
    let orderName = "";
    let searchtypeFilter = "";
    if (fromDate && toDate) {
      if (typeFilter1 != "") {
        if (typeFilter1 == "1") {
          searchtypeFilter = `AND t1.production_type='1'`;
          joinFilter = `JOIN
          orders AS t4 ON t1.order_id = t4.id`;
          orderName = `t4.order_number,`;
        } else {
          searchtypeFilter = `AND t1.production_type='2'`;
        }
      }
      dateFilter = `AND t1.CreatedDate BETWEEN '${formatDate(
        fromDate
      )} 00:00:00' AND '${formatDate(toDate)} 23:59:59'`;
      ReadRecord(`SELECT 
        t1.*, 
        t2.jumbo_type,
        t2.type,
        t3.username,
        ${orderName}
        t5.material_name,
        CASE
        WHEN t2.type = '1' THEN CONCAT(t6.label_width, ' x ', t6.label_length)
        WHEN t2.type = '2' THEN CONCAT(t7.ribbon_width, ' x ', t7.ribbon_length)
        ELSE 'The quantity is under 30'
    END AS Size
    FROM 
        production AS t1 
    JOIN 
        jumbo_master AS t2 ON t1.jumbo_id = t2.id 
    JOIN 
        user_master AS t3 ON t1.user_id = t3.id
        ${joinFilter}
    JOIN
        material_master AS t5 ON t2.material_id = t5.id
        LEFT JOIN label_master AS t6 ON t6.id = t1.label_id AND t2.type = 1
LEFT JOIN ribbon_master AS t7 ON t7.id = t1.label_id AND t2.type = 2
    WHERE 
        t1.status = 1 
        AND t1.ActiveStatus = 1   ${searchtypeFilter}  ${dateFilter} ${query} ORDER BY t1.id DESC`)
        .then((res) => {
          console.log("Fetched data:", res);
          const modifiedData = res.data[0].rows.map((item, index) => {
            let size;
            if (item.type == "1") {
              const selected = LabelOptions?.find(
                (option) => option.id === item.label_id
              );
              size = `${selected?.label_width} x ${selected?.label_length}`;
            } else {
              const selected = ribbonOptions?.find(
                (option) => option.id === item.label_id
              );
              size = `${selected?.ribbon_width} x ${selected?.ribbon_length}`;
            }
            return {
              ...item,
              sl_no: index + 1, // Adding Sl.No.
              rejected_reason: item.rejected_reason || "-", // Handle rejected_reason
              label_id: size,
              type: item.type == "1" ? "Label" : "Ribbon", // Add size based on the conditions
            };
          });

          setDownloadedData(modifiedData);
          setTimeout(() => {
            csvDownloadRef.current.link.click();
          }, 500);
        })
        .catch((error) => alert("Error happened"))
        .finally(() => setIsLoading(false));
    } else {
      setDownloadedData([]);
    }
  };

  const fetchData = (
    fromDate = null,
    toDate = null,
    typeFilter1 = radioutton,
    query = ""
  ) => {
    setIsLoading(true);
    let dateFilter = "";
    let joinFilter = "";
    let orderName = "";
    let searchtypeFilter = "";
    if (fromDate && toDate) {
      if (typeFilter1 != "") {
        if (typeFilter1 == "1") {
          searchtypeFilter = `AND t1.production_type='1'`;
          joinFilter = `JOIN
          orders AS t4 ON t1.order_id = t4.id`;
          orderName = `t4.order_number,`;
        } else {
          searchtypeFilter = `AND t1.production_type='2'`;
        }
      }
      dateFilter = `AND t1.CreatedDate BETWEEN '${formatDate(
        fromDate
      )} 00:00:00' AND '${formatDate(toDate)} 23:59:59'`;
      ReadRecord(`SELECT 
        t1.*, 
        t2.jumbo_type,
        t2.type,
        t3.username,
        ${orderName}
        t5.material_name,
        CASE
        WHEN t2.type = '1' THEN CONCAT(t6.label_width, ' x ', t6.label_length)
        WHEN t2.type = '2' THEN CONCAT(t7.ribbon_width, ' x ', t7.ribbon_length)
        ELSE 'The quantity is under 30'
    END AS Size
    FROM 
        production AS t1 
    JOIN 
        jumbo_master AS t2 ON t1.jumbo_id = t2.id 
    JOIN 
        user_master AS t3 ON t1.user_id = t3.id
        ${joinFilter}
    JOIN
        material_master AS t5 ON t2.material_id = t5.id
        LEFT JOIN label_master AS t6 ON t6.id = t1.label_id AND t2.type = 1
LEFT JOIN ribbon_master AS t7 ON t7.id = t1.label_id AND t2.type = 2
    WHERE 
        t1.status = 1 
        AND t1.ActiveStatus = 1   ${searchtypeFilter} ${query} ${dateFilter}  ORDER BY t1.id DESC`)
        .then((res) => {
          console.log("Fetched data:", res);
          setData(res.data[0].rows);
        })
        .catch((error) => alert("Error happened"))
        .finally(() => setIsLoading(false));
    }
  };

  const filteredData = filterStatus
    ? data.filter((item) => item.order_status === filterStatus)
    : data;

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      console.log("From Date:", dates[0]);
      console.log("To Date:", dates[1]);
      setFromDate(dates[0]);
      setToDate(dates[1]);
      // fetchData(dates[0], dates[1]);
      //fetchDataToDownload(dates[0], dates[1]);
    } else {
      console.log("Dates Cleared");
      setFromDate(null);
      setToDate(null);
      fetchData(null, null, radioutton, OrderStatusQuery);
    }
  };
  // const formatDate = (date) => {
  //     if (!date) return ''; // Return empty string if date is null or undefined

  //     // Ensure date is of type Date
  //     if (!(date instanceof Date)) {
  //       // If date is not a Date object, try to parse it
  //       date = new Date(date);
  //       // If parsing fails, return empty string
  //       if (isNaN(date.getTime())) return '';
  //     }

  //     // Format the date as DD-MM-YYYY
  //     const day = date.getDate().toString().padStart(2, '0');
  //     const month = (date.getMonth() + 1).toString().padStart(2, '0');
  //     const year = date.getFullYear();

  //     return `${day}-${month}-${year}`;
  //   };
  const options = [
    {
      label: "Direct",
      value: "2",
    },
    {
      label: "Order",
      value: "1",
    },
  ];
  const handleOrderType = (value) => {
    setRadioutton(value);
    if (fromDate && toDate) {
      fetchData(
        formatDate(fromDate),
        formatDate(toDate),
        value,
        OrderStatusQuery
      );
      setPagination((prevPagination) => ({
        ...prevPagination,
        current: 1,
        pageSize: pagination.pageSize,
      }));
    }
  };
  const handleTableChange = (pagination, filters) => {
    console.log("filters", filters);
    let query = "";
    const filterClauses = [];
    if (
      filters?.typee != null ||
      filters?.jumbo_type != null ||
      filters?.material_name != null ||
      filters?.production_status != null ||
      filters?.Size != null
    ) {
      setFilteredInfo(filters);
    } else {
      setFilteredInfo({});
    }
    if (filters.jumbo_type && filters.jumbo_type.length > 0) {
      const jumbo_typeInClause = filters.jumbo_type
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (jumbo_typeInClause) {
        filterClauses.push(`t1.jumbo_id IN (${jumbo_typeInClause})`);
      }
    }
    if (filters.Size && filters.Size.length > 0) {
      const labelAndRibbonInClause = filters.Size.filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (labelAndRibbonInClause) {
        filterClauses.push(`(
        (t2.type = '1' AND CONCAT(t6.label_width, ' x ', t6.label_length) IN (${labelAndRibbonInClause})) OR
        (t2.type = '2' AND CONCAT(t7.ribbon_width, ' x ', t7.ribbon_length) IN (${labelAndRibbonInClause}))
    )`);
      }
    }
    if (filters.typee && filters.typee.length > 0) {
      const typeerNameInClause = filters.typee
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (typeerNameInClause) {
        filterClauses.push(`t2.type IN (${typeerNameInClause})`);
      }
    }
    if (filters.production_status && filters.production_status.length > 0) {
      const orderStatusInClause = filters.production_status
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (orderStatusInClause) {
        filterClauses.push(`t1.production_status IN (${orderStatusInClause})`);
      }
    }
    if (filters.jumbo_type && filters.jumbo_type.length > 0) {
      const JumboTypeInClause = filters.jumbo_type
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (JumboTypeInClause) {
        filterClauses.push(`t1.jumbo_id IN (${JumboTypeInClause})`);
      }
    }
    if (filters.material_name && filters.material_name.length > 0) {
      const MaerialInClause = filters.material_name
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (MaerialInClause) {
        filterClauses.push(`t5.material_name IN (${MaerialInClause})`);
      }
    }
    if (filterClauses.length > 0) {
      query = `AND ${filterClauses.join(" AND ")}`;
    }
    setOrderStatusQuery(query);
    return fetchData(
      formatDate(fromDate),
      formatDate(toDate),
      radioutton,
      query
    );
  };
  const clearFilters = () => {
    setFilteredInfo({});
    setOrderStatusQuery("");
    fetchData(formatDate(fromDate), formatDate(toDate), radioutton, "");
  };
  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <p
          style={{
            color: "#333",
            marginRight: "auto",
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          Production Report
        </p>
        {filteredInfo && Object.keys(filteredInfo).length > 0 && (
          <Button
            onClick={clearFilters}
            style={{ borderColor: "red", color: "red" }}
          >
            Clear Filters
          </Button>
        )}
        <RangePicker
          value={[fromDate, toDate]}
          onChange={handleDateChange}
          style={{ width: 300 }}
        />
        &nbsp;
        <Radio.Group
          options={options}
          // onChange={(e) => handleTypeFilter(e)}
          onChange={(e) => handleOrderType(e.target.value)}
          value={radioutton}
          optionType="button"
          buttonStyle="solid"
        />
        &nbsp;
        <CSVLink
          headers={headers}
          data={downloadedData}
          filename={`Production_Report_${
            fromDate ? formatDate(fromDate) : "from"
          }_to_${toDate ? formatDate(toDate) : "to"}.csv`}
          className="hidden"
          ref={csvDownloadRef}
          target="_blank"
        />
        &nbsp;
        <Button
          disabled={data?.length == 0}
          style={{ backgroundColor: "rgba(175, 253, 111, 0.26)" }}
          icon={<ExportOutlined style={{ color: "green", fontSize: 24 }} />}
          onClick={() =>
            fetchDataToDownload(fromDate, toDate, radioutton, OrderStatusQuery)
          }
        >
          Export to Excel
        </Button>
        &nbsp;
        <Button
          disabled={data?.length == 0}
          style={{ backgroundColor: "rgba(255, 0, 0, 0.1)" }}
          icon={<PrinterOutlined style={{ color: "brown", fontSize: 24 }} />}
          onClick={fetchDataToDownloadPDF}
        >
          Export to PDF
        </Button>
      </div>
      {isLoading ? (
        <LoadingOutlined style={{ marginTop: 20 }}>
          Loading report...
        </LoadingOutlined>
      ) : (
        <Table
          onChange={handleTableChange}
          className="custom-table"
          rowClassName={(record, index) =>
            index % 2 === 1 ? "even-row" : "odd-row"
          }
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            // onChange: (page, pageSize) => {
            //   setPagination((prevPagination) => ({
            //     ...prevPagination,
            //     current: page,
            //     pageSize: pageSize,
            //   }));
            // },
            total: pagination.total,
          }}
          size="small"
          bordered
          id="orders-table"
          dataSource={filteredData}
          columns={columns}
          locale={{
            emptyText: isLoading ? (
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            ) : (
              <Card style={{ background: "rgb(240, 240, 240)" }}>
                <SearchOutlined />
                &nbsp; No data available
              </Card>
            ),
          }}
        />
      )}
    </div>
  );
}

export default ProductionReport;
