import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Tooltip,
  DatePicker,
  InputNumber,
  Row,
  Col,
  message,
  Tag,
  Space,
} from "antd";
import { EyeOutlined, SearchOutlined, EditOutlined } from "@ant-design/icons";
import {
  CreateRecord,
  ReadRecord,
  UpdateRecord,
} from "../../Service/API/ApiService";
import TextArea from "antd/es/input/TextArea";
import formatDate from "../../Components/formatDate";
import Typography from "antd/es/typography/Typography";

const Dispatch = () => {
  const [orders, setOrderDetails] = useState([]);
  const [editOrders, setEditOrders] = useState(null);
  const [viewOrders, setViewOrders] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isOtherMaterialVisible, setIsOtherMaterialVisible] = useState(false);
  const [otherMatData, setOtherMatData] = useState(null);
  const [form] = Form.useForm();
  const [customerOptions, setCustomerDetails] = useState(null);
  const [LocationOptions, setLocationDetails] = useState(null);
  const [MaterialOptions, setMaterialDetails] = useState(null);
  const [JumboOptions, setJumbodetails] = useState(null);
  const [ribbonOptions, setRibbonOptions] = useState([]);
  const [LabelOptions, setLabeldetails] = useState(null);
  const [labelAndRibbonData, setlabelAndRibbonData] = useState(null);
  const loggedinuser = localStorage.getItem("usernameHarnet");
  const loggedinUserId = localStorage.getItem("userID");
  const harnetRoleAccess =
    JSON.parse(localStorage.getItem("harnetRoleAccess")) || [];
  const userRoleHarnet = localStorage.getItem("userRoleHarnet") || [];
  // console.log("loginusername", loggedinuser);
  const [orderDate, setOrderDate] = useState(new Date());
  // console.log("current date", orderDate.toLocaleDateString("en-CA"));
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedCoreId, setSelectedCoreId] = useState(null);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [OrderStatusQuery, setOrderStatusQuery] = useState("");
  const { RangePicker } = DatePicker;

  const [searchQuery, setSearchQuery] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    apiCheck(
      pagination.current,
      pagination.pageSize,
      searchQuery,
      OrderStatusQuery
    );
  }, [searchQuery]);
  useEffect(() => {
    getCustomerDetails();
    getLocationDetails();
    getMaterialDetail();
    getJumboDetails();
    getLabelDetails();
    fetchRibbonOptions();
    getCoreDetails();
    getlabelAndRibbonComboDetails();
  }, []);
  function getlabelAndRibbonComboDetails() {
    ReadRecord(
      `SELECT 
        label_master.id AS id,
        '1' AS type,
         CONCAT(label_master.label_width, ' x ', label_master.label_length) AS Size
    FROM
label_master
    UNION ALL SELECT 
        ribbon_master.id AS id,
        '2' AS type,
        CONCAT(ribbon_master.ribbon_width, ' x ', ribbon_master.ribbon_length) AS Size
    FROM
        ribbon_master`
    ).then((res) => {
      setlabelAndRibbonData(res.data[0]?.rows);
    });
  }
  function getLabelDetails() {
    ReadRecord(
      `SELECT id,label_length,label_width,label_measure FROM label_master WHERE status = 1 and ActiveStatus = 1`
    ).then((res) => {
      setLabeldetails(res.data[0]?.rows);
    });
  }
  const fetchRibbonOptions = async () => {
    try {
      const response = await ReadRecord(
        `SELECT id,ribbon_length,ribbon_width,ribbon_measure FROM ribbon_master WHERE status = 1 and ActiveStatus = 1`
      );
      setRibbonOptions(response.data[0]?.rows || []);
    } catch (error) {
      console.error("Error fetching Label options:", error);
    }
  };
  function getCoreDetails() {
    ReadRecord(
      `SELECT id,core_type,core_size,core_dia,core_dia_measure,core_length,core_length_measure FROM core_master WHERE status = 1 and ActiveStatus = 1 order by id desc`
    ).then((res) => {
      setSelectedCoreId(res.data[0]?.rows || []);
    });
  }
  async function apiCheck(
    page,
    pageSize,
    search = "",
    fromDate = null,
    toDate = null,
    query = ""
  ) {
    let dateFilter = "";
    if (fromDate && toDate) {
      dateFilter = `AND t1.CreatedDate BETWEEN '${fromDate} 00:00:00' AND '${toDate} 23:59:59'`;
    }
    let searchFilter = "";
    let offset = (page - 1) * pageSize;
    if (search != "") {
      searchFilter = `AND (t1.order_number like '%${search}%'
             or t1.order_date like '%${search}%'
             or t2.customer_name like '%${search}%'
              or t3.location_name like '%${search}%'
               or t5.material_name like '%${search}%'
                or t6.username like '%${search}%'
                or t4.jumbo_type like '%${search}%'
                or t1.order_status like '%${search}%'
                or t1.order_quantity like '%${search}%'
                or t1.dispatch_number like '%${search}%')`;
      offset = 0;
    }

    let searchData = `SELECT 
        t1.*, 
        t2.customer_name, 
        t3.location_name, 
        t4.jumbo_type,
        t4.type,
        t5.material_name, 
        t6.username, 
        t7.core_size,
        t8.order_type,
         CASE
        WHEN t8.order_type = '1' THEN CONCAT(t9.label_width, ' x ', t9.label_length)
        WHEN t8.order_type = '2' THEN CONCAT(t10.ribbon_width, ' x ', t10.ribbon_length)
        ELSE 'The quantity is under 30'
    END AS Size 
    FROM 
        dispatch AS t1 
   JOIN 
        customer_master AS t2 ON t1.customer_id = t2.id 
    JOIN 
        location_master AS t3 ON t1.location_id = t3.id
        JOIN 
 jumbo_master AS t4 ON t1.jumbo_id = t4.id  
    JOIN 
 material_master AS t5 ON t4.material_id = t5.id    
    JOIN 
 user_master AS t6 ON t1.user_id = t6.id
    JOIN 
        core_master AS t7 ON t1.core_id = t7.id
    JOIN 
        orders AS t8 ON t1.order_id = t8.id
        LEFT JOIN label_master AS t9 ON t9.id = t1.label_id AND t8.order_type = 1
LEFT JOIN ribbon_master AS t10 ON t10.id = t1.label_id AND t8.order_type = 2
    WHERE 
        t1.status = 1        
        AND t1.ActiveStatus = 1 ${searchFilter} ${query} ${dateFilter} ORDER BY t1.id DESC limit ${pageSize} offset ${offset}`;

    let searchDataCount = `SELECT COUNT(*) as TotalCount
       FROM 
        dispatch AS t1 
   JOIN 
        customer_master AS t2 ON t1.customer_id = t2.id 
    JOIN 
        location_master AS t3 ON t1.location_id = t3.id
        JOIN 
 jumbo_master AS t4 ON t1.jumbo_id = t4.id  
    JOIN 
 material_master AS t5 ON t4.material_id = t5.id    
    JOIN 
 user_master AS t6 ON t1.user_id = t6.id
    JOIN 
        core_master AS t7 ON t1.core_id = t7.id
    JOIN 
        orders AS t8 ON t1.order_id = t8.id
    WHERE 
        t1.status = 1         
        AND t1.ActiveStatus = 1 ${searchFilter} ${query} ${dateFilter}`;
    const apiRequest1 = ReadRecord(searchData).then((res) => {
      console.log("apiRequest1", res);
      return res;
    });
    const apiRequest2 = ReadRecord(searchDataCount).then((res) => {
      console.log("apiRequest2", res);
      return res;
    });
    try {
      //   Execute both requests concurrently
      const [data1, data2] = await Promise.all([apiRequest1, apiRequest2]);

      //   Handle the responses
      // setOtherData(data1); // Update with the data from Endpoint1
      setOrderDetails(data1.data[0].rows); // Update with the data from Endpoint2
      let total = data2.data[0].rows[0].TotalCount; // Update with the data from Endpoint2
      // Update pagination state
      setPagination((prevPagination) => ({
        ...prevPagination,
        current: page,
        pageSize: pageSize,
        total: total, // Update total from your API response
      }));

      // Reset pagination to the first page
      //tableRef.current.onChange(1);
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
    }
  }

  function getCustomerDetails() {
    ReadRecord(
      `select * from customer_master where status=1 and ActiveStatus=1`
    ).then((res) => {
      setCustomerDetails(res.data[0]?.rows);
    });
  }

  function getLocationDetails() {
    ReadRecord(
      `select * from location_master where status=1 and ActiveStatus=1`
    ).then((res) => {
      setLocationDetails(res.data[0]?.rows);
    });
  }

  function getMaterialDetail() {
    ReadRecord(
      `select * from material_master where status=1 and ActiveStatus=1`
    ).then((res) => {
      setMaterialDetails(res.data[0]?.rows);
    });
  }

  function getJumboDetails() {
    ReadRecord(
      `select * from jumbo_master where status=1 and ActiveStatus=1`
    ).then((res) => {
      setJumbodetails(res.data[0]?.rows);
    });
  }
  const columns = [
    {
      title: "S.No.",
      dataIndex: "serial",
      key: "serial",
      width: 50,
      align: "center",
      render: (text, record, index) =>
        index + (pagination.current - 1) * pagination.pageSize + 1,
    },
    {
      title: "Dispatch ID",
      dataIndex: "dispatch_number",
      key: "order_number2",
      filterMode: "tree",
      filterSearch: true,
      width: 50,
      align: "center",
    },
    {
      title: "Order ID",
      dataIndex: "order_number",
      key: "order_number",
      filterMode: "tree",
      filterSearch: true,
      width: 50,
      align: "center",
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
      width: 50,
      align: "center",
      filters: customerOptions?.map((item) => ({
        text: item.customer_name,
        value: item.id,
      })),
      filteredValue: filteredInfo.customer_name || null,
    },
    {
      title: "Jumbo Type",
      dataIndex: "jumbo_type",
      key: "jumbo_type",
      width: 50,
      align: "center",
      filters: JumboOptions?.map((item) => ({
        text: item.jumbo_type,
        value: item.id,
      })),
      filteredValue: filteredInfo.jumbo_type || null,
    },
    {
      title: "Material Type",
      dataIndex: "material_name",
      key: "material_name",
      width: 50,
      align: "center",
      filters: MaterialOptions?.map((item) => ({
        text: item.material_name,
        value: item.material_name,
      })),
      filteredValue: filteredInfo.material_name || null,
      onFilter: (value, record) => record.material_name === value,
    },
    {
      title: "Type",
      dataIndex: "order_type",
      key: "typee",
      width: 50,
      align: "center",
      filters: [
        { text: "Label", value: "1" },
        { text: "Ribbon", value: "2" },
      ],
      filteredValue: filteredInfo.typee || null,
      onFilter: (value, record) => record.type === value,
      render: (index, record) => (record.type == "1" ? "Label" : "Ribbon"),
      render: (record) => {
        if (record == "1") {
          return `Label`;
        } else {
          return `Ribbon`;
        }
      },
    },
    {
      title: "Size",
      dataIndex: "Size",
      key: "Size",
      width: 80,
      align: "center",
      filters: labelAndRibbonData?.map((item) => ({
        text: item.Size,
        value: item.Size,
      })),
      filteredValue: filteredInfo.Size || null,
      onFilter: (value, record) => record.Size === value,
    },
    // {
    //   title: "Core Size",
    //   dataIndex: "core_size",
    //   key: "customer_name10",
    //   width: 50,
    //   align: "center",
    // },

    {
      title: "Order Quantity",
      dataIndex: "order_quantity",
      key: "order_quantity",
      width: 50,
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "order_status",
      key: "ordsts",
      width: 40,
      align: "center",
      render: (order_status) => {
        let color;
        let text;
        switch (order_status) {
          case "Completed":
            color = "green";
            break;
          case "Dispatch":
            color = "blue";
            break;
          case "New Request":
            color = "blue";
            break;
          case "Hold":
            text = "New Request";
            color = "orange";
            break;
          case "Rejected":
            color = "red";
            break;
          default:
            color = "default";
            break;
        }
        return <Tag color={color}>{order_status}</Tag>;
      },
    },
    {
      title: "Ordered Date",
      dataIndex: "order_date",
      key: "order_date",
      width: 60,
      align: "center",
      render: (order_date) => {
        // const date = new Date(order_date);
        return (
          <span style={{ whiteSpace: "nowrap" }}>{formatDate(order_date)}</span>
        ); // 'en-GB' specifies the format DD/MM/YYYY
      },
    },
    {
      title: "Dispatched Date",
      dataIndex: "CreatedDate",
      key: "CreatedDate",
      width: 60,
      align: "center",
      render: (CreatedDate) => {
        const date = new Date(CreatedDate);
        return (
          <span style={{ whiteSpace: "nowrap" }}>
            {formatDate(CreatedDate)}
          </span>
        ); // 'en-GB' specifies the format DD/MM/YYYY
      },
    },
    // {
    //     title: 'Location',
    //     dataIndex: 'location_name',
    //     key: 'location_name',
    //     width:5,

    // },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      align: "center",
      width: 100,
      render: (text, record) => (
        <>
          {(harnetRoleAccess && harnetRoleAccess.includes("26")) ||
          (userRoleHarnet && userRoleHarnet === "1") ? (
            <Button
              icon={<EyeOutlined />}
              type="primary"
              style={{ backgroundColor: "#be991d" }}
              title="View"
              onClick={() => handleView(record)}
            ></Button>
          ) : (
            ""
          )}
          &nbsp;
          {(harnetRoleAccess && harnetRoleAccess.includes("27")) ||
          (userRoleHarnet && userRoleHarnet === "1") ? (
            <Button
              icon={<EditOutlined />}
              style={{ backgroundColor: "#005c9f" }}
              type="primary"
              title="Edit"
              onClick={() => handleEdit(record)}
            ></Button>
          ) : (
            ""
          )}
          {/* &nbsp;
                    <Button icon={<EditOutlined />} style={{ backgroundColor: "#005c9f" }} type="primary" title='Edit' onClick={() => handleEdit(record)}>
                    </Button>
                    &nbsp;
                    <Button icon={<CloseOutlined />} type="primary"
                        style={{ backgroundColor: "#d7524b" }} title='Cancel' onClick={() => handleDelete(record)}>
                    </Button> */}
        </>
      ),
    },
  ];
  const handleOtherMaterial = () => {
    ReadRecord(
      `select t1.id,t3.other_material_name,t1.quantity from dispatch_child t1 join store t2 ON t1.store_id=t2.id join other_materials t3 ON t2.type_id=t3.id where t1.dispatch_id = '${viewOrders.id}' and t2.type = 2;`
    ).then((response) => {
      if (response.data[0].count > 0) {
        setIsOtherMaterialVisible(true);
        setOtherMatData(response.data[0].rows);
      } else {
        message.error("No Other Material list found!");
      }
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
    setEditOrders(null);
    setViewOrders(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setEditOrders("");
    setViewOrders("");
  };
  const handleOtherMatCancel = () => {
    setIsOtherMaterialVisible(false);
  };

  const onFinish = (values) => {
    try {
      const data = [
        {
          id: editOrders.id,
          dispatch_remarks: values.dispatch_remarks,
        },
      ];
      UpdateRecord("dispatch", data).then(() => {
        setIsModalVisible(false);
        handleCancel();
        apiCheck(
          pagination.current,
          pagination.pageSize,
          searchQuery,
          OrderStatusQuery
        );
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleView = (values) => {
    const newRecords = { ...values };
    console.log("Allfield values", newRecords);
    setIsModalVisible(true);
    setViewOrders(newRecords);
    form.setFieldsValue(newRecords);
  };

  const handleEdit = (record) => {
    const newRecords = { ...record };
    console.log("All records", newRecords);
    setIsModalVisible(true);
    setEditOrders(newRecords);
    form.setFieldsValue(newRecords);
  };

  const handleDelete = (record) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to cancel this order ?"
    );
    if (isConfirmed) {
      console.log("Before deleting record", record.id);
      let data = {
        id: record.id,
        status: false,
        ActiveStatus: false,
      };
      UpdateRecord("deleteOrders", [data]).then((res) => {
        message.info("Order has been cancelled");

        apiCheck(
          pagination.current,
          pagination.pageSize,
          searchQuery,
          OrderStatusQuery
        );

        //getOrderDetails();
        console.log("User record deleted", res);
      });
    }
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  // Handle the filter action
  const handleFilter = () => {
    // Trigger API call with new date range parameters
    apiCheck(
      pagination.current,
      pagination.pageSize,
      searchQuery,
      OrderStatusQuery
    );
  };
  const handleDateChange = (dates) => {
    if (dates && dates.length == 2) {
      setFromDate(dates[0]);
      setToDate(dates[1]);
      apiCheck(
        1,
        pagination.pageSize,
        searchQuery,
        formatDate(dates[0]),
        formatDate(dates[1])
      );
    } else {
      console.log("Dates Cleared");
      setFromDate(null);
      setToDate(null);
      // Fetch data without date filter
      apiCheck(1, pagination.pageSize, searchQuery, null, null);
    }
  };
  const handleClear = () => {
    setFromDate("");
    setToDate("");
    handleFilter();
  };
  const handleTableChange = (pagination, filters) => {
    console.log("filters", filters);

    let query = "";
    const filterClauses = [];

    if (
      filters?.material_name != null ||
      filters?.typee != null ||
      filters?.jumbo_type != null ||
      filters?.customer_name != null ||
      filters?.Size != null
    ) {
      setFilteredInfo(filters);
    } else {
      setFilteredInfo({});
    }
    if (filters.material_name && filters.material_name.length > 0) {
      const MaerialInClause = filters.material_name
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (MaerialInClause) {
        filterClauses.push(`t5.material_name IN (${MaerialInClause})`);
      }
    }
    if (filters.Size && filters.Size.length > 0) {
      const labelAndRibbonInClause = filters.Size.filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (labelAndRibbonInClause) {
        filterClauses.push(`(
        (t8.order_type = '1' AND CONCAT(t9.label_width, ' x ', t9.label_length) IN (${labelAndRibbonInClause})) OR
        (t8.order_type = '2' AND CONCAT(t10.ribbon_width, ' x ', t10.ribbon_length) IN (${labelAndRibbonInClause}))
    )`);
      }
    }
    if (filters.typee && filters.typee.length > 0) {
      const ordertypeInClause = filters.typee
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (ordertypeInClause) {
        filterClauses.push(`t4.type IN (${ordertypeInClause})`);
      }
    }
    if (filters.customer_name && filters.customer_name.length > 0) {
      const CustomerNameInClause = filters.customer_name
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (CustomerNameInClause) {
        filterClauses.push(`t1.customer_id IN (${CustomerNameInClause})`);
      }
    }
    if (filters.jumbo_type && filters.jumbo_type.length > 0) {
      const JumboTypeInClause = filters.jumbo_type
        .filter((item) => item)
        .map((item) => `'${item}'`)
        .join(", ");
      if (JumboTypeInClause) {
        filterClauses.push(`t1.jumbo_id IN (${JumboTypeInClause})`);
      }
    }
    if (filterClauses.length > 0) {
      query = `AND ${filterClauses.join(" AND ")}`;
    }
    setOrderStatusQuery(query);
    return apiCheck(
      pagination.current,
      pagination.pageSize,
      searchQuery,
      formatDate(fromDate),
      formatDate(toDate),
      query
    );
  };
  const clearFilters = () => {
    setFilteredInfo({});
    setFromDate("");
    setToDate("");
    setSearchQuery("");
    setOrderStatusQuery("");
    setTimeout(() => {
      apiCheck(pagination.current, pagination.pageSize, "", "", "", "");
    }, 0);
  };
  return (
    <div>
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            style={{
              color: "#333",
              marginRight: "auto",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            DISPATCH
          </p>
          {filteredInfo && Object.keys(filteredInfo).length > 0 && (
            <Button
              onClick={clearFilters}
              style={{ borderColor: "red", color: "red" }}
            >
              Clear Filters
            </Button>
          )}
          &nbsp;
          <RangePicker
            value={[fromDate, toDate]}
            onChange={handleDateChange}
            style={{ width: 300 }}
            allowClear={true}
          />
          &nbsp;
          <Input
            placeholder="Search"
            allowClear={handleClear}
            style={{ width: 300, margin: "0 16px" }}
            prefix={<SearchOutlined />}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <Table
          onChange={handleTableChange}
          size="small"
          bordered
          dataSource={orders}
          columns={columns}
          rowClassName={(record, index) =>
            index % 2 === 1 ? "even-row" : "odd-row"
          }
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            // onChange: (page, pageSize) => {
            //   apiCheck(page, pageSize);
            // },
            total: pagination.total,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </>
      <Modal
        title={
          editOrders
            ? "Edit Dispatch"
            : viewOrders
            ? "View Dispatch Details"
            : "Create Dispatch"
        }
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
        centered
      >
        <hr />
        &nbsp;
        <Form
          form={form}
          name="createorderform"
          onFinish={onFinish}
          layout="horizontal"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          id="orderform"
          className="orderform"
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="User name"
                name="username"
                id="username"
                className="usernname"
                initialValue={loggedinuser}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Order Date"
                name="order_date"
                id="order_date"
                className="order_date"
                initialValue={orderDate.toLocaleDateString("en-CA")}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Order Numbere"
                name="order_number"
                id="order_number"
                className="order_number"
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Dispatch Number"
                name="dispatch_number"
                id="dispatch_number"
                className="dispatch_number"
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Customer"
                name="customer_id"
                id="cust_name"
                className="cust_name"
                // rules={[{ required: true, message: "Please select Customer!" }]}
              >
                <Select
                  placeholder="Select"
                  options={
                    customerOptions &&
                    customerOptions.map((cus) => ({
                      value: cus.id,
                      label: cus.customer_name,
                    }))
                  }
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Location"
                name="location_id"
                id="loc_name"
                className="loc_name"
                // rules={[{ required: true, message: "Please select Location!" }]}
              >
                <Select
                  placeholder="Select"
                  options={
                    LocationOptions &&
                    LocationOptions.map((loc) => ({
                      value: loc.id,
                      label: loc.location_name,
                    }))
                  }
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Jumbo type"
                name="jumbo_id"
                id="jum_name"
                className="jum_name"
                // rules={[
                //   { required: true, message: "Please select Jumbo_type!" },
                // ]}
              >
                <Select
                  placeholder="Select"
                  options={
                    JumboOptions &&
                    JumboOptions.map((loc) => ({
                      value: loc.id,
                      label: loc.jumbo_type,
                    }))
                  }
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Material type"
                name="material_name"
                id="mat_name"
                className="mat_name"
                // rules={[{ required: true, message: "Please select Material!" }]}
              >
                <Select
                  placeholder="Select"
                  options={
                    MaterialOptions &&
                    MaterialOptions.map((loc) => ({
                      value: loc.id,
                      label: loc.material_name,
                    }))
                  }
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={<Tooltip title="Expected time of Arrival">ETA</Tooltip>}
                name="expected_delivery_date"
                id="exp_date"
                className="exp_date"
              >
                <Input type="date" format="YYYY-MM-DD" disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Per Roll"
                name="per_roll"
                id="per_roll"
                className="per_roll"
              >
                <InputNumber disabled min={1} max={10} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={viewOrders?.type == "1" ? "Label Size" : "Ribbon Size"}
                name="label_id"
                id="label_size"
                className="label_size"
                // rules={[
                //   { required: true, message: "Please select Label Size" },
                // ]}
              >
                <Select
                  // onChange={handleLabelChange}
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select"
                  // options={LabelOptions && LabelOptions.map(cus => ({ value: cus.id, label: cus.label_size }))}
                  disabled
                >
                  {viewOrders?.type == "1"
                    ? LabelOptions.map((label) => (
                        <Select.Option key={label.id} value={label.id}>
                          {label.label_width} x {label.label_length}{" "}
                          {label.label_measure}
                        </Select.Option>
                      ))
                    : ribbonOptions.map((label) => (
                        <Select.Option key={label.id} value={label.id}>
                          {label.ribbon_width} x {label.ribbon_length}{" "}
                          {label.ribbon_measure}
                        </Select.Option>
                      ))}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                label="Core Type"
                name="core_size"
                id="core_type"
                className="core_type"
              >
                <Input disabled />
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item
                label="Core size"
                name="core_id"
                id="core_type"
                className="core_type"
                // rules={[{ required: true, message: "Please select Core type" }]}
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select"
                  // options={selectedCoreId && selectedCoreId.map(cus => ({ value: cus.id, label: cus.core_size }))}
                  disabled
                >
                  {selectedCoreId &&
                    selectedCoreId.map((record) => (
                      <Select.Option key={record.id} value={record.id}>
                        {record.core_type +
                          " - " +
                          record.core_dia +
                          " " +
                          record.core_dia_measure +
                          " x " +
                          record.core_length +
                          " " +
                          record.core_length_measure}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Quantity"
                name="order_quantity"
                id="ord_qun"
                className="ord_qun"
              >
                <InputNumber disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Dispatch Remarks"
                name="dispatch_remarks"
                id="dispatch_remarks"
                className="dispatch_remarks"
              >
                <TextArea disabled={viewOrders} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
            <Space align="center">
              {viewOrders && (
                <Button
                  type="primary"
                  htmlType="button"
                  id="Materials"
                  className="Materials"
                  onClick={() => {
                    handleOtherMaterial();
                  }}
                >
                  View Other Used Materials
                </Button>
              )}
              {editOrders && (
                <Button
                  type="primary"
                  htmlType="submit"
                  id="UpdateDispatch"
                  className="UpdateDispatch"
                  style={{ backgroundColor: "green" }}
                >
                  Update
                </Button>
              )}
            </Space>
          </Form.Item>
          <Modal
            title="Other Materials List"
            open={isOtherMaterialVisible}
            footer={null}
            maskClosable={false}
            centered
            onCancel={handleOtherMatCancel}
          >
            <div
              style={{
                height: "210px",
                overflowX: "scroll",
                overflowX: "hidden",
                width: "100%",
              }}
            >
              <table
                style={{
                  width: "100%",
                  border: "1px solid #ccc",
                  borderCollapse: "collapse",
                }}
              >
                <thead>
                  <tr
                    style={{
                      backgroundColor: "#f0f0f0",
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                    }}
                  >
                    <th style={{ border: "1px solid #ccc", padding: "3px" }}>
                      Sl No.,
                    </th>
                    <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                      Material Name
                    </th>
                    <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                      Quantity
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {otherMatData?.map((order, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          border: "1px solid #ccc",
                          padding: "3px",
                          textAlign: "center",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{
                          border: "1px solid #ccc",
                          padding: "8px",
                          textAlign: "center",
                        }}
                      >
                        {order?.other_material_name}
                      </td>
                      <td
                        style={{
                          border: "1px solid #ccc",
                          padding: "8px",
                          textAlign: "center",
                        }}
                      >
                        {order?.quantity}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Modal>
        </Form>
      </Modal>
    </div>
  );
};
export default Dispatch;
